import { Injectable } from '@angular/core';
import { ApiItemsService } from './methods/items';
import { ApiAuthCheckService } from './methods/auth-check';
import { ApiAuthLoginService } from './methods/auth-login';
import { ApiAuthLogoutService } from './methods/auth-logout';
import { ApiNotificationsAttachmentTypesService } from '@core/service/api/methods/notifications-attachment-types';
import { ApiNotificationsService } from '@core/service/api/methods/notifications';
import { ApiOrdersService } from '@core/service/api/methods/orders';
import { ApiOrdersStatusService } from '@core/service/api/methods/orders-status';
import { ApiSpecialsService } from '@core/service/api/methods/specials';
import { ApiSpecialsStatusesService } from '@core/service/api/methods/specials-statuses';
import { ApiGroupsService } from '@core/service/api/methods/groups';
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiCitiesService } from "@core/service/api/methods/cities";
import { ApiOffersService } from "@core/service/api/methods/offers";
import { ApiSelectionsService } from "@core/service/api/methods/selections";
import { ApiBasketService } from "@core/service/api/methods/basket";
import { ApiStoriesService } from "@core/service/api/methods/stories";
import { ApiFeedbackService } from "@core/service/api/methods/feedback";
import { ApiMetricsService } from "@core/service/api/methods/metrica";
import { ApiRegionsService } from "@core/service/api/methods/regions";
import { ApiSearchAnalyticsService } from "@core/service/api/methods/search-analytics";
import { ApiClientsService } from "@core/service/api/methods/clients";
import { ApiPromocodesService } from "@core/service/api/methods/promocodes";
import { ApiQuestionsService } from "@core/service/api/methods/questions";
import { ApiYandexService } from "@core/service/api/methods/yandex";
import { ApiServiceFeedbackService } from "@core/service/api/methods/feedback-service";
import { ApiAdministrationService } from "@core/service/api/methods/administration";
import { ApiParamsService } from "@core/service/api/methods/params";
import { ApiItemsDescrService } from "@core/service/api/methods/items/description";
import { ApiPermissionService } from "@core/service/api/methods/permission";
import { ApiCertsService } from "@core/service/api/methods/certs";
import { ApiReclamesService } from "@core/service/api/methods/reclames";
import { ApiAppealsService } from "@core/service/api/methods/appeals";
import { ApiStoriesV2Service } from "@core/service/api/methods/stories/v2";
import { ApiCertsLegalService } from "@core/service/api/methods/certs-legal";
import { ApiDeliveryService } from "@core/service/api/methods/delivery";
import { ApiBannersMainService } from "@core/service/api/methods/content-site/banners-main";
import { ApiRightBannerService } from "@core/service/api/methods/content-site/right-banner";
import { ApiStoresService } from "@core/service/api/methods/stores";
import { ApiSiteSpecialsService } from "@core/service/api/methods/content-site/specials";
import { ApiRecoveryCertService } from "@core/service/api/methods/recovery-cert";
import { ApiBottomBannerService } from "@core/service/api/methods/content-site/banner-bottom";
import { ApiSiteImageService } from "@core/service/api/methods/image";
import { ApiNewsService } from "@core/service/api/methods/content-site/news";
import { ApiSocialsService } from "@core/service/api/methods/socials";
import { ApiSiteArticlesService } from "@core/service/api/methods/content-site/articles";
import { ApiSiteProjectsService } from "@core/service/api/methods/content-site/projects";
import { ApiCardsService } from "@core/service/api/methods/cards";
import { ApiBannerOrderService } from "@core/service/api/methods/banner-order";
import { ApiSiteAdvantagesService } from "@core/service/api/methods/content-site/advantages";
import { ApiProgramsService } from '@core/service/api/methods/programs';
import { ApiSpecialsTabsService } from '@core/service/api/methods/offers/specials-tabs';
import { ApiSiteWorkService } from "@core/service/api/methods/content-site/work";
import { ApiSeasonalService } from '@core/service/api/methods/offers/seasonal';
import { ApiAccentService } from '@core/service/api/methods/content-site/accent';
import { ApiBannersMobileService } from "@core/service/api/methods/content-site/banners-mobile";
import { ApiSiteCinterestService } from '@core/service/api/methods/content-site/cinterest';
import { ApiSearchHintsService } from '@core/service/api/methods/content-site/search-hints';
import { ApiMessageService } from "@core/service/api/methods/notifications/message";


@Injectable()
export class ApiService {
    constructor(
        public authCheck: ApiAuthCheckService,
        public authLogin: ApiAuthLoginService,
        public authLogout: ApiAuthLogoutService,
        public items: ApiItemsService,
        public notifications: ApiNotificationsService,
        public notificationsAttachmentTypes: ApiNotificationsAttachmentTypesService,
        public orders: ApiOrdersService,
        public ordersStatus: ApiOrdersStatusService,
        public specials: ApiSpecialsService,
        public specialsStatuses: ApiSpecialsStatusesService,
        public groups: ApiGroupsService,
        public elemsAttachment: ApiElemsAttachmentsService,
        public cities: ApiCitiesService,
        public offers: ApiOffersService,
        public selections: ApiSelectionsService,
        public basket: ApiBasketService,
        public stories: ApiStoriesService,
        public feedback: ApiFeedbackService,
        public metrics: ApiMetricsService,
        public regions: ApiRegionsService,
        public searchAnalytics: ApiSearchAnalyticsService,
        public clients: ApiClientsService,
        public promocodes: ApiPromocodesService,
        public questions: ApiQuestionsService,
        public yandex: ApiYandexService,
        public feedbackService: ApiServiceFeedbackService,
        public administration: ApiAdministrationService,
        public itemsDescr: ApiItemsDescrService,
        public params: ApiParamsService,
        public permission: ApiPermissionService,
        public certs: ApiCertsService,
        public reclames: ApiReclamesService,
        public appeals: ApiAppealsService,
        public storiesV2: ApiStoriesV2Service,
        public certs_legal: ApiCertsLegalService,
        public delivery: ApiDeliveryService,
        public bannersMain: ApiBannersMainService,
        public bannersMobile: ApiBannersMobileService,
        public bannersRight: ApiRightBannerService,
        public stores: ApiStoresService,
        public siteSpecials: ApiSiteSpecialsService,
        public recoveryCert: ApiRecoveryCertService,
        public bannersBottom: ApiBottomBannerService,
        public siteImage: ApiSiteImageService,
        public news: ApiNewsService,
        public socials: ApiSocialsService,
        public articles: ApiSiteArticlesService,
        public siteProjects: ApiSiteProjectsService,
        public cards: ApiCardsService,
        public bannerOrder: ApiBannerOrderService,
        public advantages: ApiSiteAdvantagesService,
        public programs: ApiProgramsService,
        public specialsTabs: ApiSpecialsTabsService,
        public work: ApiSiteWorkService,
        public seasonal: ApiSeasonalService,
        public accent: ApiAccentService,
        public cinterest: ApiSiteCinterestService,
        public searchHints: ApiSearchHintsService,
        public message: ApiMessageService
    ) {
    }

}

