/* Module */
import { NgModule } from '@angular/core';

/* Test */
import { fakeBackendProvider } from './mock';

/* Service */
import { ApiItemsService } from '@core/service/api/methods/items';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from '@core/service/api/api';
import { ApiAuthCheckService } from '@core/service/api/methods/auth-check';
import { ApiAuthLoginService } from '@core/service/api/methods/auth-login';
import { ApiAuthLogoutService } from '@core/service/api/methods/auth-logout';
import { ApiRequest } from '@core/service/api/request';
import { ApiNotificationsService } from '@core/service/api/methods/notifications';
import { ApiNotificationsAttachmentTypesService } from '@core/service/api/methods/notifications-attachment-types';
import { ApiOrdersService } from '@core/service/api/methods/orders';
import { ApiOrdersStatusService } from '@core/service/api/methods/orders-status';
import { ApiSpecialsService } from '@core/service/api/methods/specials';
import { ApiSpecialsStatusesService } from '@core/service/api/methods/specials-statuses';
import { ApiGroupsService } from '@core/service/api/methods/groups';
import { ApiElemsAttachmentsService } from "@core/service/api/methods/elems-attachments";
import { ApiCitiesService } from "@core/service/api/methods/cities";
import { ApiOffersService } from "@core/service/api/methods/offers";
import { ApiSelectionsService } from "@core/service/api/methods/selections";
import { ApiBasketService } from "@core/service/api/methods/basket";
import { ApiStoriesService } from "@core/service/api/methods/stories";
import { ApiFeedbackService } from "@core/service/api/methods/feedback";
import { ApiMetricsService } from "@core/service/api/methods/metrica";
import { ApiRegionsService } from "@core/service/api/methods/regions";
import { ApiSearchAnalyticsService } from "@core/service/api/methods/search-analytics";
import { ApiClientsService } from "@core/service/api/methods/clients";
import { ApiPromocodesService } from "@core/service/api/methods/promocodes";
import { ApiQuestionsService } from "@core/service/api/methods/questions";
import { ApiYandexService } from "@core/service/api/methods/yandex";
import { ApiServiceFeedbackService } from "@core/service/api/methods/feedback-service";
import { ApiAdministrationService } from "@core/service/api/methods/administration";
import { ApiParamsService } from "@core/service/api/methods/params";
import { ApiItemsDescrService } from "@core/service/api/methods/items/description";
import { ApiPermissionService } from "@core/service/api/methods/permission";
import { ApiCertsService } from "@core/service/api/methods/certs";
import { ApiReclamesService } from "@core/service/api/methods/reclames";
import { ApiAppealsService } from "@core/service/api/methods/appeals";
import { ApiStoriesV2Service } from "@core/service/api/methods/stories/v2";
import { ApiCertsLegalService } from "@core/service/api/methods/certs-legal";
import { ApiDeliveryService } from "@core/service/api/methods/delivery";
import { ApiBannersMainService } from "@core/service/api/methods/content-site/banners-main";
import { ApiRightBannerService } from "@core/service/api/methods/content-site/right-banner";
import { ApiStoresService } from "@core/service/api/methods/stores";
import { ApiSiteSpecialsService } from "@core/service/api/methods/content-site/specials";
import { ApiRecoveryCertService } from "@core/service/api/methods/recovery-cert";
import { ApiBottomBannerService } from "@core/service/api/methods/content-site/banner-bottom";
import { ApiSiteImageService } from "@core/service/api/methods/image";
import { ApiNewsService } from "@core/service/api/methods/content-site/news";
import { ApiSocialsService } from "@core/service/api/methods/socials";
import { ApiSiteArticlesService } from "@core/service/api/methods/content-site/articles";
import { ApiSiteProjectsService } from "@core/service/api/methods/content-site/projects";
import { ApiCardsService } from "@core/service/api/methods/cards";
import { ApiBannerOrderService } from "@core/service/api/methods/banner-order";
import { ApiSiteAdvantagesService } from "@core/service/api/methods/content-site/advantages";
import { ApiProgramsService } from '@core/service/api/methods/programs';
import { ApiSpecialsTabsService } from '@core/service/api/methods/offers/specials-tabs';
import { ApiSiteWorkService } from "@core/service/api/methods/content-site/work";
import { ApiSeasonalService } from '@core/service/api/methods/offers/seasonal';
import { ApiAccentService } from '@core/service/api/methods/content-site/accent';
import { ApiBannersMobileService } from "@core/service/api/methods/content-site/banners-mobile";
import { ApiSiteCinterestService } from '@core/service/api/methods/content-site/cinterest';
import { ApiSearchHintsService } from '@core/service/api/methods/content-site/search-hints';
import { ApiMessageService } from "@core/service/api/methods/notifications/message";

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        ApiRequest,
        ApiService,
        ApiAuthCheckService,
        ApiAuthLoginService,
        ApiAuthLogoutService,
        ApiItemsService,
        ApiNotificationsService,
        ApiNotificationsAttachmentTypesService,
        ApiOrdersService,
        ApiOrdersStatusService,
        ApiSpecialsService,
        ApiSpecialsStatusesService,
        ApiGroupsService,
        ApiElemsAttachmentsService,
        ApiCitiesService,
        ApiOffersService,
        ApiSelectionsService,
        ApiBasketService,
        ApiStoriesService,
        ApiFeedbackService,
        ApiMetricsService,
        ApiRegionsService,
        ApiSearchAnalyticsService,
        ApiClientsService,
        ApiPromocodesService,
        ApiQuestionsService,
        ApiYandexService,
        ApiServiceFeedbackService,
        ApiAdministrationService,
        ApiParamsService,
        ApiItemsDescrService,
        ApiPermissionService,
        ApiCertsService,
        ApiReclamesService,
        ApiAppealsService,
        ApiStoriesV2Service,
        ApiCertsLegalService,
        ApiDeliveryService,
        ApiBannersMainService,
        ApiRightBannerService,
        ApiStoresService,
        ApiSiteSpecialsService,
        ApiRecoveryCertService,
        ApiBottomBannerService,
        ApiSiteImageService,
        ApiNewsService,
        ApiSocialsService,
        ApiSiteProjectsService,
        ApiSiteArticlesService,
        ApiCardsService,
        ApiBannerOrderService,
        ApiSiteAdvantagesService,
        ApiProgramsService,
        ApiSpecialsTabsService,
        ApiSiteWorkService,
        ApiSeasonalService,
        ApiAccentService,
        ApiBannersMobileService,
        ApiSiteCinterestService,
        ApiSearchHintsService,
        ApiMessageService,
        // api backend simulation
        fakeBackendProvider,
    ],
    exports: []
})

export class ApiModule {
}
