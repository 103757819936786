import { Injectable } from '@angular/core';
import { ApiRequest, Response } from './../../../request';
import { InPost, Get, InPut, InGet } from './interface';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/service/config';
import { ApiUtilsService } from './../../../utils';

@Injectable()
export class ApiMessageService extends ApiUtilsService {

    constructor(protected _request: ApiRequest,
        protected _activatedRoute: ActivatedRoute,
        protected _config: Config) {
        super(_request, _activatedRoute, _config);
    }

    /*
     * Создание
     *
     * @param {InPost} obj
     * @return {Promise<any>}
     */
    post(obj: InPost): Promise<any> {
        return this._request.create('arm/v1/notifications/message', obj)
            .then((res: Response) => res.json)
    }

    /*
     * Список
     *
     * @param {InGet} obj
     * @return {Promise<Get>}
     */
    get(obj: InGet): Promise<Get> {
        return this._request
            .query('arm/v1/notifications/message', this.setParamsUrl(obj))
            .then((res: Response) => res.json);
    }

    /**
     * Обновление
     * @param obj
     * @param files
     * @returns {Promise<{result: boolean}|TResult2|TResult1>}
     */
    put(obj: InPut): Promise<any> {
        return this._request
            .update('arm/v1/notifications/message', obj)
            .then((res: Response) => res.json)
    }
}

